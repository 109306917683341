<template>
  <div v-if="!(this.store.current.form === 'view-pdf-document' && this.store.pivotFullScreen)">
    <div class="menu-main" :class="{sticky: isSticky}">
      <img class="hov" src="" @click="toggleStickyMenu">
      <h3 v-if="!this.store.isUser && this.store.current.data.pdf.ddocument != null && this.store.current.data.pdf.document.needsAccessKey">Welkom bij {{ this.store.appName }}</h3>
      <template v-else>
        <div class="button" v-if="store.isUser" @click="projects()"><span></span>Projecten</div>
        <div class="button" v-if="store.isUser" @click="messages()"><span></span>Berichten ({{ messageCount }})</div>
        <div class="button" v-if="store.isRegistrar" @click="menuRegistrar()"><span></span>Admin</div>
      </template>

      <img v-if="!this.isFullscreen" src="img/ico/maximize.png" class="button" @click="fullscreen()" style="float:right" title="Volledig scherm">
      <img v-else src="img/ico/minimize.png" class="button" @click="fullscreen()" style="float:right" title="Volledig scherm">

      <div v-if="this.store.current.data.pdf.document != null && !this.store.current.data.pdf.document.needsAccessKey" class="button" @click="toggleStickyMenu" style="float:right"><span></span>Verberg menu</div>
      <div class="button" @click="about()" style="float:right"><span></span>About</div>
      <div class="button" v-if="!store.isUser" @click="login()" style="float:right"><span></span>Inloggen</div>
      <div class="button" v-else @click="logout()" style="float:right"><span></span>Uitloggen</div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import userMessageService from '@/services/user.message.service'

export default {
  emits: ['logoutUser', 'resetData'],
  data() {
    return {
      messageCount: 0,
      isFullscreen: false
    }
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    isSticky() {
      if(this.store.current.data.pdf.document != null && this.store.current.data.pdf.document.needsAccessKey) return true;
      else if(this.store.current.form != "view-pdf-document") return true;
      else if(this.store.menuSticky) return true;
      else return false;
    }
  },
  mounted() {
    this.store.components.menuMain = this;

    if(this.store.messageTimer != null) clearInterval(this.store.messageTimer);
    this.store.messageTimer = setInterval(function() {
      this.updateMessageCount();
    }.bind(this), 60 * 1000);
    
    this.updateMessageCount();
  },
  unmounted() {
    clearInterval(this.store.messageTimer);
  },
  methods: {
    projects() {
      //this.$router.push('/');
      this.resetData();
      this.store.current.form = "list-pdf-projects";
    },
    messages() {
      this.store.current.popup = ["message-center"];
    },
    menuRegistrar() {
      //this.$router.push('/');
      this.resetData();
      this.store.current.form = "menu-admin";
    },
    about() {
      //this.$router.push('/');
      this.resetData();
      this.store.current.form = "about";
    },
    login() {
      this.store.current.popup = ["login-user"];
    },
    logout() {
      this.$emit('logoutUser');
    },
    async updateMessageCount() {
      await new Promise(resolve => setTimeout(resolve, 1500));
      if(this.store.isUser) {
        this.messageCount = await userMessageService.count();
      }
    },
    resetData() {
      this.store.current.data.pdf.project = null;
      this.store.current.data.pdf.document = null;
      this.store.current.data.pdf.contribution = null;
    },
    toggleStickyMenu() {
      this.store.menuSticky = !this.store.menuSticky;
    },
    fullscreen() {
      if(document.body.requestFullscreen) {
        if(this.isFullscreen) document.exitFullscreen();
        else document.body.requestFullscreen();
        this.isFullscreen = !this.isFullscreen;
      }
      else if(document.body.webkitRequestFullscreen) {
        if(this.isFullscreen) document.webkitExitFullscreen();
        else document.body.webkitRequestFullscreen();
        this.isFullscreen = !this.isFullscreen;
      }
    }
  }
}
</script>

