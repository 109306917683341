<template>
  <div id="pivot">
    <table ref="dataHolder" class="pivot">
      <tr class="ph header">
        <template v-for="(header, index) in thead" :key="index">
          <td :style="{width: (header.width != null)?header.width.toFixed(2)+'%':0+'%'}"></td>
        </template>
      </tr>
      <tr class="ph">
        <template v-for="(header, index) in thead" :key="index">
          <th v-if="header.sortable" :title="header.name_nl">
            <div>
              <span>{{ header.name_nl }}</span>
              <span @click="sortTableBy(index)">
                <img v-if="header.sort == 'desc'" src="img/ico/expand.png">
                <img v-else src="img/ico/collapse.png">
              </span>
            </div>
          </th>
          <th v-else-if="index > 0" :title="header.name_nl">
            <span>{{ header.name_nl }}</span>
          </th>
        </template>
      </tr>

      <template v-for="(rowData, index) in pivotData" :key="index">
        <template v-if="hasDifferentSortName(rowData, index)">
          <tr class="div" n="1"><td colspan="4"></td></tr>
          <tr class="sin tot">
            <template v-for="(n,i) in thead.length" :key="i">
              <td v-if="thead[i].name != this.renderStore.options.sortContributionsBy.column">
                <span>{{ (findTotal(rowData)[thead[i].name] && findTotal(rowData)[thead[i].name] != 0) ? findTotal(rowData)[thead[i].name]+thead[i].suf : '' }}</span>
              </td>
              <td v-else>
                <span>{{ rowData[thead[i].name] }}</span>
              </td>
            </template>
          </tr>
        </template>
        <tr @click="selectContribution(rowData, index)" class="sin" :pid="rowData[rowData.length - 2]" :mid="rowData[rowData.length - 1]" :class="{active:((activeContribution != null && activeContribution.contributionId == rowData.contributionId)||renderStore.options.multiSelectedContributionIds.indexOf(rowData.contributionId)>=0)}">
          <template v-for="(n,i) in thead.length" :key="i">
            <td v-if="active.rowIndex != index || active.fieldIndex != i" @dblclick="editValue(index, i)">
              <span>
                <template v-if="thead[i].name == 'title'">
                  <template v-if="rowData.athumbnails != null">
                    <span style="font-weight:bold;float:right"><img src="img/ico/has_img.png">{{ rowData.athumbnails.length }}</span>
                  </template>
                  <template v-if="rowData.answers != null && rowData.answers.length > 0">
                    <span style="font-weight:bold;float:right"><img src="img/ico/has_cmt.png">{{ rowData.answers.length }}</span>
                  </template>
                </template>
                {{ findValue(rowData, thead[i]) }}
              </span>
            </td>
            <td v-else>
              <input v-if="active.type == 'text'" type="text" id="input" :value="findValue(rowData, thead[i])" @keydown.enter="setValue" @blur="deselect">
              <input v-if="active.type == 'number'" type="number" id="input" :value="findValue(rowData, thead[i])" @keydown.enter="setValue" @blur="deselect">
              <select v-if="active.type == 'select'" id="input" @change="setValue" @blur="deselect">
                <template v-for="option of active.options">
                  <option :value="findValue(rowData, thead[i])" :selected="findValue(rowData, thead[i]) == option?true:false">{{option}}</option>
                </template>
              </select>
            </td>
          </template>
        </tr>
      </template>
    </table>
    <template v-if="pivotData == null || Object.entries(pivotData).length == 0">
      <table class="pivot">
        <tr class="ph red">
          <td :colspan="thead.length">
            Er kunnen geen gegevens worden getoond. Mogelijk voldoen geen van de markeringen aan 
            uw filtercriteria of bevat dit document geen gegevens.
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import { selectAnnotation, renderAnnotations } from '@/components/app/viewer/PdfCanvas.js'
import contributionService from '@/services/app/contribution.service'
import { nextTick } from 'vue'

var previousSortName = "";
var selectedByMouse = false;

export default {
  data() {
    return {
      active: { rowIndex: null, fieldIndex: null, type: "text" },
      options: [],
      unit: ""
    }
  },
  emits: ['showMessage', 'updateParent'],
  props: {
    pivotData: {
      type: Object,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    thead: {
      type: Array,
      required: true
    },
    getData: Object
  },
  computed: {
    store() {
      return this.$parent.store;
    },
    renderStore() {
      return this.$parent.renderStore;
    },
    activeContribution() {
      return this.$parent.renderStore.options.activeContribution;
    },
  },
  mounted() {
    this.renderStore.components.contributionTable = this;
    document.addEventListener("keyup", this.onKeyUp);
  },
  watch: {
    activeContribution: {
      handler() {
        var self = this;
        nextTick(function() {
          if(!selectedByMouse && self.renderStore.options.selectedContributionId != -1) {
            const row = document.querySelectorAll(".sin.active");
            if(row != null && row[0] != null) {
              const prevRow = $(row[0]).prev().prev().prev()[0];
              if(prevRow != null) prevRow.scrollIntoView();
              return true;
            }
          }
          selectedByMouse = false;
        });
      },
      deep: true
    }
  },
  methods: {
    isObject() {
      if(typeof Object.values(this.pivotData)[0] == "object") return true;
      else return false;
    },
    hasDifferentSortName(data, index) {
      if(index == 0) return true;
      if(previousSortName == "" || data[this.renderStore.options.sortContributionsBy.column] !== previousSortName) {
        previousSortName = data[this.renderStore.options.sortContributionsBy.column];
        return true;
      }
      return false;
    },
    findValue(rowData, field) {
      const ct = this.renderStore.contributionTemplates[rowData.contributionTemplateId];
      var suf = (field.suf !== undefined)?field.suf:'';
      var fieldName = field.name.replace("values.", "");

      for(var input of ct.inputs) {
        if(input.name == fieldName && input.type == "select") {
          return input.options[""+rowData.values[fieldName]];
        }
      }

      if(rowData.values && rowData.values[fieldName]) {
        return rowData.values[fieldName] + suf;
      }
      else if(rowData[fieldName]) {
        return rowData[fieldName] + suf;
      }
      else return "";
    },
    findTotal(rowData) {
      var column = this.renderStore.options.sortContributionsBy.column;
      return this.totals[rowData[column]];
    },
    sortTableBy(colId) {
      if(this.thead[colId].name != this.renderStore.options.sortContributionsBy.column) {
        this.renderStore.options.sortContributionsBy.column = this.thead[colId].name;
        this.renderStore.options.sortContributionsBy.order = "asc";
      }
      else if(this.renderStore.options.sortContributionsBy.order == "asc") {
        this.renderStore.options.sortContributionsBy.order = "desc";
      }
      else {
        this.renderStore.options.sortContributionsBy.order = "asc";
      }
    },
    expandToLevel(level) {
      var allRows = this.$refs.dataHolder.getElementsByTagName("tr");
      for(const row of allRows) {
        if(row.classList.contains("ph")) continue;
        var thisLevel = row.getAttribute("n");
        if(thisLevel == null) thisLevel = -1;

        if(thisLevel == level - 1) {
          row.classList.add("collapsed");
          row.classList.remove("inv");
        }
        else if(thisLevel < level - 1) {
          //row.classList.add("collapsed");
          row.classList.add("inv");
        }
        else {
          row.classList.remove("collapsed");
          row.classList.remove("inv");
        }
      }
    },
    findColumnValue(rowData, colId) {
      var col = this.thead[colId];
      if(col != null && (col.type == 'Number' || col.type == 'Formula') && this.isNumeric(rowData[colId])) {
        if(parseFloat(rowData[colId]) == 0) {
          if(col.unit == "") return (0).toFixed(col.precision);
          else return "";
        }
        else if(col.unit != null) {
          return parseFloat(rowData[colId]).toFixed(col.precision) + " " + this.convertToText(col.unit);
        }
        else if(col.name == "totaal") {
          return parseFloat(rowData[colId]).toFixed(col.precision) + " " + this.convertToText(this.unit);
        }
        else return parseFloat(rowData[colId]).toFixed(col.precision);
      }
      else return this.convertToText(rowData[colId]);
    },
    selectContribution(data, key) {
      selectedByMouse = true;
      var keyInt = parseInt(key);
      this.store.current.data.pdf.contribution = {...data};
      if(this.renderStore.options.shiftPressed) {
        this.renderStore.options.activeContribution = null;
        this.renderStore.options.multiSelectedContributionIds = [];
        if(this.renderStore.options.firstSelectedContributionKey != -1) {
          if(keyInt < this.renderStore.options.firstSelectedContributionKey) {
            for(var i = keyInt; i <= this.renderStore.options.firstSelectedContributionKey; i++) {
              this.renderStore.options.multiSelectedContributionIds.push(this.pivotData[i].contributionId);
            }
          }
          else {
            for(var i = this.renderStore.options.firstSelectedContributionKey; i <= keyInt; i++) {
              this.renderStore.options.multiSelectedContributionIds.push(this.pivotData[i].contributionId);
            }
          }
        }
        else {
          this.renderStore.options.firstSelectedContributionKey = key;
          this.renderStore.options.multiSelectedContributionIds = [this.pivotData[key].contributionId];
        }

        if (window.getSelection) {
          if (window.getSelection().empty) {
            window.getSelection().empty();
          } 
          else if (window.getSelection().removeAllRanges) {
            window.getSelection().removeAllRanges();
          }
        }

        renderAnnotations(true);
      }
      else {
        this.renderStore.options.firstSelectedContributionKey = key;
        selectAnnotation(data.pageNumber, "Q"+data.contributionId, true);
      }
    },
    convertToText(value) {
      try { return value.replace(/&#(\d+);/g, function (m, n) { 
        return String.fromCharCode(n); }); 
      }
      catch(e) { return ""; }
    },
    scrollToActiveRow() {
      document.querySelectorAll(".sin").forEach(row => {
        row.classList.remove("active");
      });
      
      const row = document.querySelectorAll(".sin[pid=\""+this.renderStore.options.pageNumber+"\"][mid=\""+this.renderStore.options.selectedAnnotationId+"\"]");
      if(row != null && row[0] != null) {
        row[0].classList.add("active");
        row[0].scrollIntoView();
        return true;
      }
      else return false;
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    updateUnit(unit) {
      this.unit = unit;
    },
    findMeasureCount(data) {
      var count = 0;

      if(this.renderStore.annotations.data.filter.length > 0) {
        return this.filterCount;
      }

      if(typeof data[0] === 'number') {
        return data[0];
      }

      Object.keys(data).forEach(key => {
        if(typeof data[key][0] !== 'number') {
          count += this.findMeasureCount(data[key]);
        }
        else count += data[key][0];
      }, count);
      return count;
    },
    editValue(rowIndex, fieldIndex) {
      var contribution = this.pivotData[rowIndex];
      var ct = this.renderStore.contributionTemplates[contribution.contributionTemplateId];
      var field = this.thead[fieldIndex];
      var selection;
      
      for(var fieldDef of this.renderStore.columnsContribDef) {
        switch(fieldDef.name) {
          case "values.mm1":
          case "values.mm2":
          case "values.mh":
          case "values.ma":
            if(fieldDef.name == field.name) {
              selection = fieldDef;
            }
            break;
        }
      }

      for(var input of ct.inputs) {
        if(input.name == field.name) {
          selection = input;
          break;
        }
      }

      if(selection == null) return;

      switch(selection.type) {
        
        case "select":
          this.active = {
            rowIndex: rowIndex,
            fieldIndex: fieldIndex,
            type: selection.type,
            options: selection.options
          };
          nextTick(function() {
            //document.getElementById("input").click();
          });
          break;

        case "text":
        case "number":
          this.active = {
            rowIndex: rowIndex,
            fieldIndex: fieldIndex,
            type: selection.type
          };
          nextTick(function() {
            document.getElementById("input").focus();
            document.getElementById("input").select();
          });
          break;
      }
    },
    async setValue(e) {
      var ct = this.pivotData[this.active.rowIndex];
      var field = this.thead[this.active.fieldIndex];
      var value = "";
      switch(this.active.type) {
        case "select":
          value = e.srcElement.options[e.srcElement.selectedIndex].text;
          break;
        case "text":
        case "number":
          value = e.srcElement.value;
          break;
      }
      var response = await contributionService.setValue(ct.contributionId, field.name, value, this.getData);
      if(response) {
        console.log(response.data);
        if(response.status == 200) {
          this.$emit('updateParent', response.data);
          this.deselect();
        }
        else {
          this.$emit('showMessage', response);
          this.formErrors = errorHandler.process(response.data, "");
        }
      }
      this.deselect();
    },
    deselect() {
      this.active = {
        rowIndex: null,
        fieldIndex: null,
        type: "text"
      }
    },
    onKeyUp(e) {
      switch(e.keyCode) {

        // ESCAPE
        case 27:
          this.deselect();
          break;
      }
    },
  },
  components: {
    
  }
}
</script>

<style scoped>
input, select {
  border-radius: 0;
  margin: 0;
  width: 100%;
  height: 100% !important;
  padding: 2px 4px !important;
  border-width: 1px;
  font-size: 10px;
  box-sizing: border-box;
  font-style: normal !important;
}

select {
  padding-left: 0px !important;
}
</style>